import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import HowItWorks from "./components/HowItWork";
import CustomerAnecdote from "./components/CustomerAnecdote";
import TransformInteractions from "./components/TransformInteractions";
import Footer from "./components/Footer";
import ContactModal from "./components/Modal";
import Toast from "./components/Toast";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SolutionsPage from "./pages/Solutions";
import Terms from "./pages/Terms";
import Policy from "./pages/Policy";
import Blogs from "./pages/Blogs";

function App() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isToastVisible, setToastVisible] = useState(false);
  const [reason, setReason] = useState("");

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (isModalOpen && e.key === "Escape") {
        setModalOpen(false);
      }
    });
  }, [isModalOpen]);

  const handleOpenModal = (reason = "") => {
    if (reason) {
      setReason(reason);
    }
    setModalOpen(true);
  };

  const handleCloseModal = (success) => {
    setModalOpen(false);
    if (success) {
      setToastVisible(true);
    }
  };

  const handleCloseToast = () => {
    setToastVisible(false);
  };

  return (
    <div className="font-sans bg-secondary-50 text-gray-900">
      {/* Toast and modal */}

      <BrowserRouter>
        <Navbar toggleModal={handleOpenModal} />
        <ContactModal
          reason={reason}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
        />
        <Toast
          message="Your message has been sent successfully!"
          isVisible={isToastVisible}
          onClose={handleCloseToast}
        />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HeroSection toggleModal={handleOpenModal} />
                <HowItWorks />
                <CustomerAnecdote />
                <TransformInteractions toggleModal={handleOpenModal} />
              </>
            }
          />
          <Route
            path="/solutions"
            element={<SolutionsPage toggleModal={handleOpenModal} />}
          />
          <Route path="/terms-conditions" element={<Terms />} />
          <Route path="/privacy-policy" element={<Policy />} />
          <Route path="/blogs" element={<Blogs />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
